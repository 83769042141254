
.propertie {
  .unselected {
    border-color: lightgrey !important;
  }
  option {
    color: black;
    &:disabled {
      color: lightgrey;
    }
  }
}
